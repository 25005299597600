import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Dayjs } from 'dayjs';

export const billingKeys = createQueryKeys('whatsapp-billing', {
  getWabaConversationUsageAnalytics: (
    fbbaId: string,
    wabaId: string,
    start: Dayjs | undefined,
    end: Dayjs | undefined,
  ) => [{ fbbaId, wabaId, start, end }],
  useGetWabaTopUpProfile: ({
    fbbaId,
    wabaId,
  }: {
    fbbaId: string;
    wabaId?: string | undefined;
  }) => [{ fbbaId, wabaId }],
  useGetWabaAllTimeUsage: ({
    fbbaId,
    wabaId,
  }: {
    fbbaId: string;
    wabaId?: string | undefined;
  }) => [{ fbbaId, wabaId }],
});

export const conversationalAutomationKeys = createQueryKeys(
  'whatsapp-conversational-automation',
  {
    getConversationalAutomationList: (wabaId: string) => [{ wabaId }],
  },
);
