import { inject, injectable } from 'inversify';
import {
  catchError,
  concatMap,
  map,
  Observable,
  shareReplay,
  switchMap,
  throwError,
} from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { LogService } from '@/services/logs/log.service';
import { RxjsUtils } from '@/services/rxjs-utils/rxjs-utils';
import { generateV1RedirectionLink } from '@/utils/v1-utils';

import { AuthService } from '../auth.service';

export interface UserWorkspace {
  is_default: boolean;
  server_location: string;
  sleekflow_company_id: string;
}

@injectable()
export class TenantService {
  constructor(
    @inject(AuthService)
    private authService: AuthService,
    @inject(LogService)
    private logService: LogService,
  ) {}

  public getDefaultUserWorkspace$() {
    return this.getUserWorkspaces$().pipe(
      map((userWorkspaces) => {
        return userWorkspaces.find((uw) => uw.is_default) || userWorkspaces[0];
      }),
    );
  }

  private userWorkspaces$$?: Observable<UserWorkspace[]> = undefined;

  public getUserWorkspaces$(): Observable<UserWorkspace[]> {
    if (!this.userWorkspaces$$) {
      this.userWorkspaces$$ = this.authService.getAccessTokenSilently$().pipe(
        concatMap((accessToken) => {
          return ajax<{
            data: {
              user_workspaces: UserWorkspace[];
            };
          }>({
            url:
              import.meta.env.VITE_SLEEKFLOW_API_BASE_URL +
              '/v1/tenant-hub/UserWorkspaces/GetUserWorkspaces',
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + accessToken,
            },
            body: {},
          });
        }),
        RxjsUtils.getRetryAPIRequest(),
        catchError((error) => {
          // Check if error status is 500
          if (error.status === 500) {
            // Call IsCompanyRegistered API
            return this.checkCompanyRegistration();
          }
          // If not a 500 error, rethrow it
          return throwError(() => error);
        }),
        map((resp) => {
          return resp.response.data.user_workspaces;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        }),
      );
    }

    return this.userWorkspaces$$;
  }

  private checkCompanyRegistration() {
    return this.authService.getAccessTokenSilently$().pipe(
      switchMap((accessToken) => {
        return ajax<boolean>({
          url:
            import.meta.env.VITE_SLEEKFLOW_API_BASE_URL +
            '/v1/tenant-hub/Register/Companies/IsCompanyRegistered',
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }).pipe(
          switchMap((resp) => {
            if (resp.response) {
              // If true, retry the original request or handle appropriately
              return throwError(
                () =>
                  new Error(
                    'Company is registered but GetUserWorkspaces failed.',
                  ),
              );
            } else {
              return this.authService
                .logout$({
                  openUrl: false,
                  logoutParams: {
                    federated: false,
                  },
                })
                .pipe(
                  switchMap(() => {
                    return throwError(
                      () => new Error('Company not registered.'),
                    );
                  }),
                );
            }
          }),
          catchError((error) => {
            setTimeout(() => {
              this.logService.log('Redirecting to another page');

              const v1Url = generateV1RedirectionLink({
                path: '/',
              });
              window.location.replace(v1Url);
            }, 500);

            // Handle errors from IsCompanyRegistered API
            return throwError(() => error);
          }),
        );
      }),
    );
  }
}
