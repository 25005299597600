// https://mui.com/material-ui/getting-started/supported-platforms/#browser
export const SUPPORTED_BROWSER = [
  {
    name: 'Chrome',
    version: '109',
    label: 'Chrome 109',
  },
  {
    name: 'Chrome', // edge and chrome use the same engine
    version: '121',
    label: 'Edge 121',
  },
  {
    name: 'Firefox',
    version: '115',
    label: 'Firefox 115',
  },
  {
    name: 'Safari',
    version: '15.4',
    label: 'Safari 15.4',
  },
];
