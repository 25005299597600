import { useGetIsFeatureFlagsEnabledQuery } from '@/api/common';
import { useCompany, useSuspenseCompany } from '@/api/company';
import { CompanyTypeDict } from '@/api/types';
import { PERMISSION_KEY } from '@/constants/permissions';
import {
  PLAN_MIGRATION_ALERT_TIERS_LIST,
  PlanMigrationAlertTierKeys,
  useGetPlanKeyAndTier,
} from '@/hooks/useGetPlanKeyAndTier';
import { useMyProfile } from '@/hooks/useMyProfile';
import { usePermissionWrapper } from '@/hooks/usePermission';
import { useSuspenseAccessRuleGuard } from '@/pages/Contacts/shared/accessRuleGuard/useAccessRuleGuard';
import useCustomObjectDataAccessControl from '@/pages/CustomObjectData/hooks/useCustomObjectDataAccessControl';
import {
  isEnterprisePlan,
  isFreeOrFreemiumPlan,
  isLegacyPlan,
  isPremiumPlan,
  isProPlan,
} from '@/utils/subscription-plan-checker';

const COMPANIES_TEST_PRIVACY =
  import.meta.env.VITE_COMPANIES_TEST_PRIVACY ?? '';

export const useSettingAccessRuleGuard = () => {
  const accessRulesGuard = useSuspenseAccessRuleGuard();
  const companyId = useSuspenseCompany({
    select: (data) => data.id,
  });
  const { planKey, tier } = useGetPlanKeyAndTier();

  const currentPlan =
    accessRulesGuard.company.data?.currentPlan.billRecord?.subscriptionPlan;
  const addOnStatus = accessRulesGuard.company.data?.addOnPlanStatus;
  const { data: myProfile } = useMyProfile();
  const isWhatsappQrCodeEnabled = addOnStatus?.isWhatsappQrCodeEnabled;
  const { data: isResellerClient } = useCompany({
    select: (data) => {
      return (
        data.companyType === CompanyTypeDict.resellerClient ||
        data.companyType === CompanyTypeDict.reseller
      );
    },
  });
  const { data: isPaymentFailed } = useCompany({
    select: (data) => {
      return data.isPaymentFailed;
    },
  });
  const isSuperAdmin = (): boolean => !!myProfile?.isCompanyOwner;
  const isAdmin = (): boolean => {
    return myProfile?.roleType === 'Admin';
  };
  const isAdminOrTeamAdmin = (): boolean => {
    return isAdmin() || myProfile?.roleType === 'TeamAdmin';
  };

  const { check } = usePermissionWrapper();
  const [canManagePlansAndBillings, canViewSummary] = check(
    [
      PERMISSION_KEY.plansAndBillingsPlanAndSubscriptionManage,
      PERMISSION_KEY.planSummaryAccess,
    ],
    [isAdmin(), isAdmin()],
  );

  const { canAccessCustomObjectData, isLoading: isCheckingCustomObjectAccess } =
    useCustomObjectDataAccessControl();
  const { data: isFeatureFlagsEnabled } = useGetIsFeatureFlagsEnabledQuery();

  // company settings
  const isCompanyDetailTabAllowToView = () =>
    check(
      [PERMISSION_KEY.companySettingsCompanyDetailsView],
      [isAdminOrTeamAdmin()],
    )[0];
  const isCompanyDetailTabAllowToEdit = () =>
    check(
      [PERMISSION_KEY.companySettingsCompanyDetailsEdit],
      [isAdminOrTeamAdmin()],
    )[0];

  // user management
  const isUserManagementAllowToView = () =>
    check([PERMISSION_KEY.companySettingsUserView], [isAdminOrTeamAdmin()])[0];
  const isUserManagementAllowToInvite = () =>
    check(
      [PERMISSION_KEY.companySettingsUserInvite],
      [isAdminOrTeamAdmin()],
    )[0];
  const isUserManagementAllowToEdit = () =>
    check([PERMISSION_KEY.companySettingsUserEdit], [isAdminOrTeamAdmin()])[0];
  const isUserManagementResetPasswordButtonAllowToUse = () =>
    check(
      [PERMISSION_KEY.companySettingsUserSecuritySettingsEdit],
      [isAdmin()],
    )[0];
  const isUserManagementAllowToDelete = () =>
    check(
      [PERMISSION_KEY.companySettingsUserDelete],
      [isAdminOrTeamAdmin()],
    )[0];

  // team
  const isTeamAllowToView = () => {
    if (!currentPlan) return false;
    return !isFreeOrFreemiumPlan(currentPlan) && !isProPlan(currentPlan);
  };
  const isTeamManagementAllowToView = () => {
    return (
      isTeamAllowToView() &&
      check([PERMISSION_KEY.companySettingsTeamView], [isAdmin()])[0]
    );
  };
  const isTeamManagementAllowToEdit = () => {
    return (
      isTeamAllowToView() &&
      check([PERMISSION_KEY.companySettingsTeamEdit], [isAdmin()])[0]
    );
  };

  // roles
  const isRoleManagementAllowToView = () =>
    check([PERMISSION_KEY.companySettingsRoleView], [isSuperAdmin()])[0];
  const isRoleManagementAllowToCreate = () =>
    check([PERMISSION_KEY.companySettingsRoleCreate], [isSuperAdmin()])[0];
  const isRoleManagementAllowToEdit = () =>
    check([PERMISSION_KEY.companySettingsRoleEdit], [isSuperAdmin()])[0];

  // audit log
  const isAuditLogAllowToView = () =>
    check([PERMISSION_KEY.companySettingsAuditLogView], [isAdmin()])[0];

  // 2fa
  const is2FAFieldAllowToView = () => {
    if (!currentPlan) return false;
    return !isFreeOrFreemiumPlan(currentPlan);
  };
  const isUserManagement2FARevokeButtonAllowToUse = () =>
    check(
      [PERMISSION_KEY.companySettingsUserSecuritySettingsEdit],
      [isAdmin()],
    )[0] && is2FAFieldAllowToView();
  const is2FARevokeWarningAllowToView = () => {
    return (
      !check(
        [PERMISSION_KEY.companySettingsUserSecuritySettingsEdit],
        [isAdmin()],
      )[0] && is2FAFieldAllowToView()
    );
  };
  const is2FASettingAllowToView = () => {
    return (
      is2FAFieldAllowToView() &&
      check(
        [PERMISSION_KEY.companySettingsSecuritySettingsView],
        [isAdmin()],
      )[0]
    );
  };
  const is2FASettingAllowToEdit = () =>
    is2FAFieldAllowToView() &&
    check([PERMISSION_KEY.companySettingsSecuritySettingsEdit], [isAdmin()])[0];

  // ip whitelisting
  const isIpWhitelistingAllowToView = () => {
    if (!currentPlan) return false;
    return isPremiumPlan(currentPlan) || isEnterprisePlan(currentPlan);
  };
  const isIpWhitelistingSettingsAllowToView = () =>
    isIpWhitelistingAllowToView() &&
    check([PERMISSION_KEY.companySettingsSecuritySettingsView], [isAdmin()])[0];
  const isIpWhitelistingSettingsAllowToEdit = () =>
    isIpWhitelistingAllowToView() &&
    check([PERMISSION_KEY.companySettingsSecuritySettingsEdit], [isAdmin()])[0];

  const isQuickSharingTabAllowToView = () => {
    if (!currentPlan || !myProfile?.qrCodeChannel) return false;
    if (isLegacyPlan(currentPlan.version)) {
      return isEnterprisePlan(currentPlan) || isWhatsappQrCodeEnabled;
    } else {
      return (
        isWhatsappQrCodeEnabled ||
        isEnterprisePlan(currentPlan) ||
        isPremiumPlan(currentPlan)
      );
    }
  };

  const isSubscriptionsAllowToView = () =>
    canManagePlansAndBillings || canViewSummary;
  const isInboxSettingsAllowToView = () => isAdmin();
  const isWelcomeMessageAllowToView = () => isAdmin();

  const isInvoiceAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return (
      !isResellerClient &&
      !isFreeOrFreemiumPlan(currentPlan) &&
      canManagePlansAndBillings &&
      !isPaymentFailed
    );
  };
  const isSupportServicesAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return (
      !isResellerClient &&
      !isFreeOrFreemiumPlan(currentPlan) &&
      canManagePlansAndBillings
    );
  };
  const isAddOnsAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return (
      !isResellerClient &&
      !isFreeOrFreemiumPlan(currentPlan) &&
      canManagePlansAndBillings
    );
  };
  const isAddOnsAllowToEdit = () => isAddOnsAllowToView();

  const isCompanySummaryAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return !isResellerClient && canViewSummary;
  };

  const isPaymentMethodButtonAllowToEdit = () => {
    if (!currentPlan) {
      return false;
    }
    return (
      !isResellerClient &&
      !isFreeOrFreemiumPlan(currentPlan) &&
      canManagePlansAndBillings
    );
  };

  const isManagePlanAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return !isResellerClient && canManagePlansAndBillings;
  };

  const isManageCustomObjectsAllowToView = () => {
    if (isCheckingCustomObjectAccess) return true;
    return canAccessCustomObjectData;
  };

  const isLabelsAllowToManage = isAdmin();

  const isDataPrivacyAllowToView = () => {
    return (
      isAdmin() &&
      (addOnStatus?.isPiiMaskingEnabled ||
        (import.meta.env.VITE_USER_NODE_ENV !== 'production' &&
          COMPANIES_TEST_PRIVACY.includes(companyId.data)))
    );
  };

  const isDeletedContactAllowToView = () => isAdmin();
  const isTicketingAllowToView = isAdmin();

  const canEditCompanySleekFlowLabFeatures = isAdmin();

  const isPlanTierDisplayPlanMigrationAlert: (
    planKey: PlanMigrationAlertTierKeys,
  ) => boolean = (planKey: PlanMigrationAlertTierKeys) => {
    if (!planKey || !tier) return false;
    return PLAN_MIGRATION_ALERT_TIERS_LIST[
      planKey as keyof typeof PLAN_MIGRATION_ALERT_TIERS_LIST
    ]?.includes(tier);
  };
  const isProPlanDisplayPlanMigrationAlert: () => boolean = () => {
    if (!currentPlan || !isFeatureFlagsEnabled || !planKey) return false;
    return (
      isProPlan(currentPlan) &&
      isLegacyPlan(currentPlan.version) &&
      canManagePlansAndBillings &&
      isFeatureFlagsEnabled.isPlanMigrationIncentiveCampaignPeriod &&
      isPlanTierDisplayPlanMigrationAlert(planKey)
    );
  };
  const isPremiumPlanDisplayPlanMigrationAlert: () => boolean = () => {
    if (!currentPlan || !isFeatureFlagsEnabled || !planKey) return false;
    return (
      isPremiumPlan(currentPlan) &&
      isLegacyPlan(currentPlan.version) &&
      canManagePlansAndBillings &&
      isFeatureFlagsEnabled?.isPlanMigrationIncentiveCampaignPeriod &&
      isPlanTierDisplayPlanMigrationAlert(planKey)
    );
  };
  const isIntegrationDisconnectAlertAllowToView = () => isAdmin();

  return {
    canEditCompanySleekFlowLabFeatures,
    isTeamAllowToView,
    isTeamManagementAllowToEdit,
    is2FAFieldAllowToView,
    is2FARevokeWarningAllowToView,
    isQuickSharingTabAllowToView,
    is2FASettingAllowToView,
    isCompanyDetailTabAllowToView,
    isUserManagementAllowToView,
    isTeamManagementAllowToView,
    isInboxSettingsAllowToView,
    isWelcomeMessageAllowToView,
    isInvoiceAllowToView,
    isSubscriptionsAllowToView,
    isSupportServicesAllowToView,
    isAddOnsAllowToView,
    isAddOnsAllowToEdit,
    isManageCustomObjectsAllowToView,
    isAuditLogAllowToView,
    isDataPrivacyAllowToView,
    isCompanySummaryAllowToView,
    isPaymentMethodButtonAllowToEdit,
    isManagePlanAllowToView,
    isLabelsAllowToManage,
    isDeletedContactAllowToView,
    isTicketingAllowToView,
    isProPlanDisplayPlanMigrationAlert,
    isPremiumPlanDisplayPlanMigrationAlert,
    isIntegrationDisconnectAlertAllowToView,
    isRoleManagementAllowToView,
    isCompanyDetailTabAllowToEdit,
    isUserManagementAllowToInvite,
    isUserManagementAllowToEdit,
    isUserManagementResetPasswordButtonAllowToUse,
    isUserManagementAllowToDelete,
    isUserManagement2FARevokeButtonAllowToUse,
    isRoleManagementAllowToCreate,
    isRoleManagementAllowToEdit,
    is2FASettingAllowToEdit,
    isIpWhitelistingSettingsAllowToView,
    isIpWhitelistingSettingsAllowToEdit,
  };
};
