export const LABELS_COLOR_MAPPING = {
  Blue: 'blue',
  Pink: 'brown',
  Yellow: 'mustard',
  Green: 'forest',
  Red: 'red',
  Purple: 'purple',
  Grey: 'gray',
  Cyan: 'indigo',
} as const;
