import { TravisBackendMessageDomainViewModelsExtendedMessagePayloadViewModel } from '@sleekflow/sleekflow-core-typescript-rxjs-apis';

import type { ConversationMessageWrapper } from '@/services/conversation-messages/managers/conversation-message-wrapper';

export function isWhatsappCloudApiAdMessage(
  extendedMessagePayload:
    | TravisBackendMessageDomainViewModelsExtendedMessagePayloadViewModel
    | undefined
    | null,
) {
  if (extendedMessagePayload == null) return false;
  return (
    extendedMessagePayload?.extendedMessagePayloadDetail
      ?.whatsappCloudApiReferral != null
  );
}

export function isFacebookAdMessage(
  extendedMessagePayload:
    | TravisBackendMessageDomainViewModelsExtendedMessagePayloadViewModel
    | undefined
    | null,
) {
  if (extendedMessagePayload == null) return false;
  return (
    extendedMessagePayload?.extendedMessagePayloadDetail
      ?.facebookAdClickToMessengerObject != null
  );
}

export function isInstagramStoryMessage(
  conversationMessageWrapper: ConversationMessageWrapper | undefined | null,
) {
  if (conversationMessageWrapper == null) return false;
  return (
    conversationMessageWrapper?.getStoryUrl() !== null &&
    conversationMessageWrapper?.getStoryUrl() !== undefined
  );
}

export const isWhatsappFlowSubmissionMessage = (
  extendedMessagePayload?: TravisBackendMessageDomainViewModelsExtendedMessagePayloadViewModel,
): extendedMessagePayload is TravisBackendMessageDomainViewModelsExtendedMessagePayloadViewModel => {
  const interactiveReply =
    extendedMessagePayload?.extendedMessagePayloadDetail
      ?.whatsappCloudApiInteractiveReply;

  return !!(
    interactiveReply?.type === 'nfm_reply' &&
    interactiveReply?.nfm_reply?.name === 'flow' &&
    interactiveReply?.nfm_reply?.response_json?.includes('__sleekflow_metadata')
  );
};
