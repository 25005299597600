import { User } from '@auth0/auth0-react';
import {
  CompanyApi,
  CompanyTeamApi,
  MessagingChannelApi,
  SleekflowApisMessagingHubModelBusinessBalanceDto,
  TravisBackendChannelDomainModelsEmailConfig,
  TravisBackendChannelDomainModelsLineConfig,
  TravisBackendChannelDomainModelsTelegramConfig,
  TravisBackendChannelDomainModelsViberConfig,
  TravisBackendChannelDomainModelsWeChatConfig,
  TravisBackendChannelDomainModelsWhatsAppConfigViewModel,
  TravisBackendChannelDomainViewModelsWhatsApp360DialogConfigViewModel,
  TravisBackendControllersMessageControllersMessagingChannelControllerGetAvailableChannelsResults,
  TravisBackendConversationDomainViewModelsCompanyResponse,
  TravisBackendConversationServicesModelsFacebookConfig,
  TravisBackendModelsChatChannelConfigWhatsappCloudApiConfig,
  WhatsappCloudApiApi,
} from '@sleekflow/sleekflow-core-typescript-rxjs-apis';
import { TravisBackendConversationServicesModelsInstagramConfig } from '@sleekflow/sleekflow-core-typescript-rxjs-apis/dist/models/TravisBackendConversationServicesModelsInstagramConfig';
import { inject, injectable } from 'inversify';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  startWith,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

import type { CompanyUsageResponse } from '@/api/types';
import { StaffCore } from '@/services/companies/types';
import {
  CACHE_REFRESHING_BEHAVIOUR,
  RxjsUtils,
} from '@/services/rxjs-utils/rxjs-utils';

import { SignalRService } from '../signal-r/signal-r.service';

export type MessagingChannel =
  | ({
      channelType: 'instagram';
    } & TravisBackendConversationServicesModelsInstagramConfig)
  | ({
      channelType: 'facebook';
    } & TravisBackendConversationServicesModelsFacebookConfig)
  | ({
      channelType: 'whatsapp';
    } & TravisBackendChannelDomainModelsWhatsAppConfigViewModel)
  | ({
      channelType: 'whatsapp360dialog';
    } & TravisBackendChannelDomainViewModelsWhatsApp360DialogConfigViewModel)
  | ({
      channelType: 'whatsappcloudapi';
      facebookWabaBusinessId: string;
    } & TravisBackendModelsChatChannelConfigWhatsappCloudApiConfig)
  | ({
      channelType: 'telegram';
    } & TravisBackendChannelDomainModelsTelegramConfig)
  | ({ channelType: 'line' } & TravisBackendChannelDomainModelsLineConfig)
  | ({ channelType: 'wechat' } & TravisBackendChannelDomainModelsWeChatConfig)
  | ({ channelType: 'viber' } & TravisBackendChannelDomainModelsViberConfig)
  | ({ channelType: 'email' } & TravisBackendChannelDomainModelsEmailConfig)
  | {
      channelType: 'sms';
      channelDisplayName?: string | null;
      channelIdentityId: null;
      legacyChannelId: string | null;
    }
  | {
      channelType: 'note';
      channelDisplayName?: string | null;
      channelIdentityId: null;
    }
  | {
      channelType: 'web';
      channelDisplayName?: string | null;
      channelIdentityId: null;
    };

export interface Team {
  id: number;
  name: string;
  admins: Staff[];
  members: Staff[];
}

export interface Staff {
  displayName: string;
  connectionStrategy?: User['https://app.sleekflow.io/connection_strategy'];
  id: string;
  staffId: number;
  firstName: string;
  lastName: string;
  email: string;
  associatedTeams: CompanyTeam[];
  roleType?: string;

  // Should show the name when sending the message
  shouldShowSenderName: boolean;
  status: string;
  pictureUrl?: string;
}

export interface CompanyTeam {
  id: number;
  teamName: string;
}

@injectable()
export class CompanyService {
  constructor(
    @inject(MessagingChannelApi)
    private messagingChannelApi: MessagingChannelApi,
    @inject(CompanyTeamApi) private companyTeamApi: CompanyTeamApi,
    @inject(CompanyApi) private companyApi: CompanyApi,
    @inject(WhatsappCloudApiApi)
    private whatsappCloudApiApi: WhatsappCloudApiApi,
    @inject(SignalRService) private signalRService: SignalRService,
  ) {
    this.signalRService
      .getWhatsappBusinessBalance$()
      .pipe(
        withLatestFrom(
          this.whatsappCloudApiBusinessBalancesReplaySubject$$.pipe(
            map((balances) => balances),
          ),
        ),
        map(([balanceChanged, balances]) => {
          const index = balances.findIndex(
            (balance) =>
              balance.facebook_business_id ===
              balanceChanged.facebook_business_id,
          );

          if (index !== -1) {
            const updatedBalances = [...balances];
            updatedBalances[index] = balanceChanged;
            return updatedBalances;
          }

          return balances;
        }),
      )
      .subscribe((updatedBalances) => {
        this.whatsappCloudApiBusinessBalancesReplaySubject$$?.next(
          updatedBalances,
        );
      });
  }

  private allTeamsReplaySubject$$?: ReplaySubject<Team[]> = undefined;
  private displayableMessageChannels$$ = new BehaviorSubject<
    MessagingChannel[] | null
  >(null);
  private displayableMessageChannels$ = this.displayableMessageChannels$$.pipe(
    map((channels) => channels || []),
  );

  public getAllTeams$(
    cacheRefreshingBehaviour: CACHE_REFRESHING_BEHAVIOUR = CACHE_REFRESHING_BEHAVIOUR.NEVER_REFRESH,
  ): Observable<Team[]> {
    // If we don't add this to return the observable, the whole app doesn't work
    if (
      this.allTeamsReplaySubject$$ &&
      cacheRefreshingBehaviour !==
        CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_SERVER
    ) {
      return this.allTeamsReplaySubject$$.asObservable().pipe(take(1));
    }

    const { replaySubject$$: allTeamsReplaySubject$$, observable$: allTeams$ } =
      RxjsUtils.cacheAndRetryObservable<Team[]>(
        () => this.allTeamsReplaySubject$$,
        this.companyTeamApi
          .v2CompanyTeamGet({
            offset: 0,
            limit: 300,
          })
          .pipe(
            map((teams: any) => {
              return teams.map((obj: any) => {
                const team: Team = {
                  id: obj.id,
                  name: obj.teamName,
                  admins: obj.teamAdmins.map((m: any) => {
                    return {
                      id: m.userInfo.id,
                      staffId: m.staffId,
                      firstName: (m.userInfo.firstName || '').trim(),
                      lastName: (m.userInfo.lastName || '').trim(),
                      email: m.userInfo.email,
                    };
                  }),
                  members: obj.members.map((m: any) => {
                    return {
                      id: m.userInfo.id,
                      staffId: m.staffId,
                      firstName: (m.userInfo.firstName || '').trim(),
                      lastName: (m.userInfo.lastName || '').trim(),
                      email: m.userInfo.email,
                    };
                  }),
                };

                return team;
              });
            }),
          ),

        cacheRefreshingBehaviour ===
          CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_SERVER,
      );

    this.allTeamsReplaySubject$$ = allTeamsReplaySubject$$;

    if (
      cacheRefreshingBehaviour ===
      CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_CLIENT
    ) {
      return this.allTeamsReplaySubject$$.asObservable();
    }

    return allTeams$;
  }

  private allStaffsCoreReplaySubject$$?: ReplaySubject<StaffCore[]> = undefined;

  public getAllStaffsCore$(
    cacheRefreshingBehaviour: CACHE_REFRESHING_BEHAVIOUR = CACHE_REFRESHING_BEHAVIOUR.NEVER_REFRESH,
  ) {
    const {
      replaySubject$$: allStaffsOverviewReplaySubject$$,
      observable$: allStaffs$,
    } = RxjsUtils.cacheAndRetryObservable<StaffCore[]>(
      () => this.allStaffsCoreReplaySubject$$,
      combineLatest({
        allStaffs: this.companyApi.companyStaffOverviewsGet({
          offset: 0,
          limit: 1000,
        }),
      }).pipe(
        map(({ allStaffs }) => {
          // it doesn't always have associatedTeams, we need to use allTeams to fill in the missing data in allStaffs
          return allStaffs.map((staff) => {
            let pictureUrl = staff.profilePicture?.url ?? null;
            pictureUrl = pictureUrl
              ? import.meta.env.VITE_API_BASE_URL + pictureUrl
              : pictureUrl;

            return {
              ...staff,
              profilePictureUrl: pictureUrl,
              status: staff.status ?? 'Active',
            } as StaffCore;
          });
        }),
        catchError((error) => {
          console.error('Unable to fetch getAllStaffs$.', error);
          return [];
        }),
      ),
      cacheRefreshingBehaviour ===
        CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_SERVER,
    );

    this.allStaffsCoreReplaySubject$$ = allStaffsOverviewReplaySubject$$;

    if (
      cacheRefreshingBehaviour ===
      CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_CLIENT
    ) {
      return this.allStaffsCoreReplaySubject$$
        .asObservable()
        .pipe(startWith([] as StaffCore[]));
    }

    // Some places require the initial value to be an empty array
    return allStaffs$.pipe(startWith([] as StaffCore[]));
  }

  private companyReplaySubject$$?: ReplaySubject<
    TravisBackendConversationDomainViewModelsCompanyResponse | null | undefined
  > = undefined;

  public getCompany$(
    cacheRefreshingBehaviour: CACHE_REFRESHING_BEHAVIOUR = CACHE_REFRESHING_BEHAVIOUR.NEVER_REFRESH,
  ): Observable<
    TravisBackendConversationDomainViewModelsCompanyResponse | null | undefined
  > {
    const { replaySubject$$: company$$, observable$: company$ } =
      RxjsUtils.cacheAndRetryObservable<
        | TravisBackendConversationDomainViewModelsCompanyResponse
        | null
        | undefined
      >(
        () => this.companyReplaySubject$$,
        this.companyApi.companyGet(),
        cacheRefreshingBehaviour ===
          CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_SERVER,
      );

    this.companyReplaySubject$$ = company$$;

    if (
      cacheRefreshingBehaviour ===
      CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_CLIENT
    ) {
      return this.companyReplaySubject$$.asObservable();
    }

    return company$;
  }

  private companyUsage$?: Observable<CompanyUsageResponse | null | undefined> =
    undefined;

  public getCompanyUsage$(): Observable<
    CompanyUsageResponse | null | undefined
  > {
    if (!this.companyUsage$) {
      this.companyUsage$ = this.companyApi.companyUsageGet().pipe(
        map((usage: any) => {
          return {
            billingPeriodUsages: usage.billingPeriodUsages,
            maximumAutomatedMessages: usage.maximumAutomatedMessages,
            maximumContacts: usage.maximumContacts,
            totalChannelAdded: usage.totalChannelAdded,
            totalContacts: usage.totalContacts,
            totalConversations: usage.totalConversations,
            totalMessages: usage.totalMessages,
            totalMessagesSentFromSleekFlow:
              usage.totalMessagesSentFromSleekFlow,
            currentNumberOfChannels: usage.currentNumberOfChannels,
            maximumNumberOfChannel: usage.maximumNumberOfChannel,
            maximumAgents: usage.maximumAgents,
            totalAgents: usage.totalAgents,
          } as CompanyUsageResponse;
        }),
        take(1),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        }),
      );
    }
    return this.companyUsage$;
  }

  private whatsappCloudApiBusinessBalancesReplaySubject$$: ReplaySubject<
    Array<SleekflowApisMessagingHubModelBusinessBalanceDto>
  > = new ReplaySubject(1);

  public getWhatsappCloudApiBalances$(
    cacheRefreshingBehaviour: CACHE_REFRESHING_BEHAVIOUR = CACHE_REFRESHING_BEHAVIOUR.NEVER_REFRESH,
  ) {
    const shouldRefresh =
      cacheRefreshingBehaviour ===
      CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_SERVER;

    const {
      replaySubject$$: whatsappCloudApiBalancesReplaySubject$$,
      observable$: whatsappCloudApiBalances$,
    } = RxjsUtils.cacheAndRetryObservable(
      () => this.whatsappCloudApiBusinessBalancesReplaySubject$$,
      this.whatsappCloudApiApi.companyWhatsappCloudapiBalancesGet().pipe(
        map((response: any) => {
          return (response ??
            []) as Array<SleekflowApisMessagingHubModelBusinessBalanceDto>;
        }),
      ),
      shouldRefresh,
    );

    this.whatsappCloudApiBusinessBalancesReplaySubject$$ =
      whatsappCloudApiBalancesReplaySubject$$;

    if (shouldRefresh) {
      return this.whatsappCloudApiBusinessBalancesReplaySubject$$.asObservable();
    }

    return whatsappCloudApiBalances$;
  }

  private availableChannelsReplaySubject$$?: ReplaySubject<TravisBackendControllersMessageControllersMessagingChannelControllerGetAvailableChannelsResults> =
    undefined;

  public getAvailableChannels$(
    cacheRefreshingBehaviour: CACHE_REFRESHING_BEHAVIOUR = CACHE_REFRESHING_BEHAVIOUR.NEVER_REFRESH,
  ) {
    const {
      replaySubject$$: availableChannelsReplaySubject$$,
      observable$: availableChannels$,
    } =
      RxjsUtils.cacheAndRetryObservable<TravisBackendControllersMessageControllersMessagingChannelControllerGetAvailableChannelsResults>(
        () => this.availableChannelsReplaySubject$$,
        this.messagingChannelApi.messagingChannelsGetAvailableChannelsPost(),
        cacheRefreshingBehaviour ===
          CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_SERVER,
      );

    this.availableChannelsReplaySubject$$ = availableChannelsReplaySubject$$;

    if (
      cacheRefreshingBehaviour ===
      CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_CLIENT
    ) {
      return this.availableChannelsReplaySubject$$.asObservable();
    }

    return availableChannels$;
  }

  public getDisplayableMessageChannels$(
    cacheRefreshingBehaviour: CACHE_REFRESHING_BEHAVIOUR = CACHE_REFRESHING_BEHAVIOUR.NEVER_REFRESH,
  ) {
    if (this.displayableMessageChannels$$.getValue() !== null) {
      return this.displayableMessageChannels$;
    }

    this.getAvailableChannels$(cacheRefreshingBehaviour)
      .pipe(
        map((messagingChannels) => {
          return [
            ...(messagingChannels.whatsAppConfigs ?? []),
            ...(messagingChannels.whatsApp360DialogConfigs ?? []),
            ...(messagingChannels.whatsappCloudApiConfigs ?? []),
            ...(messagingChannels.facebookConfigs ?? []),
            ...(messagingChannels.instagramConfigs ?? []),

            ...(messagingChannels.emailConfig
              ? [messagingChannels.emailConfig]
              : []),
            ...(messagingChannels.weChatConfig
              ? [messagingChannels.weChatConfig]
              : []),
            ...(messagingChannels.lineConfigs ?? []),
            ...(messagingChannels.viberConfigs ?? []),
            ...(messagingChannels.telegramConfigs ?? []),
            ...(messagingChannels.smsConfigs ?? []),
            {
              channelType: 'web',
              channelDisplayName: 'Live Chat', // i18n to be handled in component level
              channelIdentityId: 'live-chat',
            },
            {
              channelType: 'note' as const,
              channelDisplayName: 'Note',
              channelIdentityId: null,
            },
          ].map((mc) => {
            const myMc = mc as any;

            if (myMc.channelType === 'facebook') {
              myMc.channelDisplayName =
                myMc.channelDisplayName ?? myMc.pageName ?? '';
            }

            if (myMc.channelType === 'email') {
              myMc.channelDisplayName =
                myMc.channelDisplayName ??
                myMc.email ??
                myMc.channelIdentityId ??
                '';
            }

            if (myMc.channelType === 'sms') {
              myMc.channelDisplayName =
                myMc.channelDisplayName ??
                myMc.smsSender ??
                myMc.channelIdentityId ??
                '';
            }

            return mc as MessagingChannel;
          });
        }),
        RxjsUtils.getRetryAPIRequest(),
        tap((messagingChannels) =>
          this.displayableMessageChannels$$.next(messagingChannels),
        ),
      )
      .subscribe();

    return this.displayableMessageChannels$;
  }

  public getIsLoadingDisplayableMessageChannels$() {
    return this.displayableMessageChannels$$.pipe(
      map((displayableMessageChannels) => displayableMessageChannels === null),
    );
  }
}
