export const AUDIO_TYPES = {
  'audio/webm': {
    extension: 'webm',
  },
  'audio/ogg': {
    extension: 'ogg',
  },
  'audio/mpeg': {
    extension: 'mp3',
  },
  'audio/mp3': {
    extension: 'mp3',
  },
  'audio/wav': {
    extension: 'wav',
  },
} as { [type: string]: { extension: string } };
