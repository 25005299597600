import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookie } from 'react-use';

import {
  useGetPermissionListByRoleNameQueries,
  settingsKeys,
  PermissionListResponse,
  useGetIsRbacEnabledQuery,
} from '@/api/settings';
import { PermissionKey, PERMISSION_DICT } from '@/constants/permissions';

import { useMyProfile } from './useMyProfile';

export function usePermission(options?: { isEnabledRbac?: boolean }): {
  isLoading: boolean;
  check: (permissions: PermissionKey[]) => boolean[];
  setEnabled: (enabled: boolean) => void;
} {
  const { isEnabledRbac } = options || {};
  const { data: myProfile } = useMyProfile();
  const [enabled, setEnabled] = useState(isEnabledRbac || false);
  const rbacRoles = myProfile?.rbacRoles || [];
  const results = useGetPermissionListByRoleNameQueries({
    roleNames: rbacRoles.map((role) => role.role_name),
    enabled: rbacRoles.length > 0 && enabled,
  });

  const check = useCallback(
    (permissions: PermissionKey[]) => {
      const enabledPermissionsKeysFromServer = results
        .map((role) => role.data?.permissions || [])
        .reduce((acc, item) => {
          return [...new Set([...acc, ...item])];
        }, []);

      return permissions.map((key) => {
        const currentResource = PERMISSION_DICT[key];
        return currentResource?.every((item) =>
          enabledPermissionsKeysFromServer.includes(item),
        );
      });
    },
    [results],
  );

  return {
    isLoading: results.some((item) => item.isLoading),
    setEnabled,
    check,
  };
}

type CheckWrapper = (
  permissionKeys: PermissionKey[],
  oldAccessControls?: boolean[],
) => boolean[];

export function usePermissionWrapper() {
  const { data, isLoading: isEnabledLoading } = useGetIsRbacEnabledQuery({
    enabled: import.meta.env.VITE_USER_NODE_ENV !== 'production',
  });
  const { check, setEnabled, isLoading } = usePermission({
    isEnabledRbac: !!data?.is_enabled,
  });

  useEffect(() => {
    if (data) {
      setEnabled(data.is_enabled);
    }
  }, [data, setEnabled]);

  const checkWrapper: CheckWrapper = useCallback(
    (permissionKeys, oldAccessControls) => {
      if (data?.is_enabled) {
        return check(permissionKeys);
      } else {
        return permissionKeys.map((_, index) => {
          return !!oldAccessControls?.[index];
        });
      }
    },
    [check, data],
  );

  return {
    check: checkWrapper,
    isLoading: isEnabledLoading || isLoading,
    isEnabledRbac: !!data?.is_enabled,
  };
}

const SLEEP = 'sleep';

function flatPermissions(
  permissionArray: (undefined | PermissionListResponse)[],
) {
  const flatPermission = permissionArray
    .flatMap((item) => item?.permissions)
    .filter(Boolean);
  return [...new Set(flatPermission)].sort();
}

function compareOldAndNewPermissions(
  oldPermissionsArray: (undefined | PermissionListResponse)[],
  newPermissionsArray: (undefined | PermissionListResponse)[],
) {
  const oldPermissions = flatPermissions(oldPermissionsArray);
  const newPermissions = flatPermissions(newPermissionsArray);
  return oldPermissions.join() === newPermissions.join();
}

export function useRefetchPermission() {
  const navigate = useNavigate();
  const [value, updateCookie] = useCookie('sleeper');
  const { data: myProfile } = useMyProfile();
  const rbacRoles = myProfile?.rbacRoles || [];
  const queryClient = useQueryClient();
  const previousPermissions = useRef(
    rbacRoles.map(
      (role) =>
        queryClient.getQueryData(
          settingsKeys.getPermissionListByRoleName(role.role_name),
        ) as PermissionListResponse,
    ),
  );
  const { data: isEnabledRbac } = useGetIsRbacEnabledQuery({
    enabled: import.meta.env.VITE_USER_NODE_ENV !== 'production',
  });
  const permissionQueries = useGetPermissionListByRoleNameQueries({
    roleNames: rbacRoles.map((role) => role.role_name),
    enabled: false,
  });

  const refetch = useCallback(async () => {
    if (value === SLEEP || !isEnabledRbac?.is_enabled) {
      return;
    }
    const latestPermissions = await Promise.all(
      permissionQueries.map(async (result) => {
        return await result.refetch();
      }),
    );
    const inTenSec = new Date(new Date().getTime() + 10 * 1000);
    updateCookie(SLEEP, { expires: inTenSec });
    const isSame = compareOldAndNewPermissions(
      previousPermissions.current,
      latestPermissions.map((item) => item.data),
    );
    if (!isSame) {
      previousPermissions.current = latestPermissions
        .map((item) => item.data)
        .filter((item): item is PermissionListResponse => !!item?.permissions);

      navigate(0);
    }
  }, [navigate, permissionQueries, updateCookie, value]);

  return {
    isLoading: permissionQueries.some((item) => item.isLoading),
    refetch,
  };
}
